import LoginForm from "./Components/LoginForm";
import LoginImg from "./assets/LoginBack.svg";

export default function LoginPage() {
  return (
    <>
      <div className="w-screen h-screen flex-1 flex justify-center items-center bg-gradient-to-r from-cyan-50 to-sky-200 transition-all">
        <div className="flex flex-col md:flex-row flex-1 w-full h-full justify-evenly items-center transition-all">
          <div className=" hidden md:w-2/4 md:flex">
            <img
              srcSet={`${LoginImg}?w=700 700w,
                ${LoginImg}?w=500 500w,
                ${LoginImg}?w=300 300w`}
              sizes="(max-width: 700px) 100vw,
              (max-width: 500px) 50vw,
              700px"
              src={LoginImg}
              alt="Inicia sesión en la aplicacion del PESV"
              loading="lazy"
              width={700}
            />
          </div>
          <div className="w-full h-full md:w-auto md:h-auto">
            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
}
